/*
 * Globals
 */

* {
  font-family: "Montserrat", sans-serif !important;
}

.about-me {
  background-color: transparent;

  /* Links */
  a,
  a:focus,
  a:hover {
    color: cyan !important;
  }
}

// /* Custom default button */
// .btn-secondary,
// .btn-secondary:hover,
// .btn-secondary:focus {
//   color: #333;
//   text-shadow: none; /* Prevent inheritance from `body` */
//   background-color: #fff;
//   border: 0.05rem solid #fff;
// }

// /*
//  * Base structure
//  */

html {
  height: 100%;
}

body {
  width: auto;
  min-height: 100%;
  position: relative;
  // background: linear-gradient(45deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0) 100%);
  // background: linear-gradient(217deg, rgb(0, 0, 0 / 60%), rgb(0, 0, 0) 100%);
  // background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
  color: #2a2a2a !important;
  background-color: #151515 !important;
}

// body {
//   display: -ms-flexbox;
//   display: -webkit-box;
//   display: block;
//   -ms-flex-pack: center;
//   -webkit-box-pack: center;
//   justify-content: center;
//   color: #fff;
//   text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
//   box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
//   background-color: rgba(0, 255, 255, 1);
// }

// .cover-container {
//   max-width: 42em;
// }

// /*
//  * Header
//  */
// .masthead {
//   margin-bottom: 2rem;

//   .masthead-brand {
//     margin-bottom: 0;
//   }

//   .nav-masthead {
//     .nav-link {
//       padding: 0.25rem 0;
//       font-weight: 700;
//       color: rgba(255, 255, 255, 0.5);
//       background-color: transparent;
//       border-bottom: 0.25rem solid transparent;
//     }

//     .nav-link:hover,
//     .nav-link:focus {
//       border-bottom-color: rgba(255, 255, 255, 0.25);
//     }
//   }
// }

// .nav-masthead .nav-link + .nav-link {
//   margin-left: 1rem;
// }

// .nav-masthead .active {
//   color: #fff;
//   border-bottom-color: #fff;
// }

// @media (min-width: 48em) {
//   .masthead-brand {
//     float: left;
//   }
//   .nav-masthead {
//     float: right;
//   }
// }

// /*
//  * Cover
//  */
// .cover {
//   padding: 0 1.5rem;
// }
// .cover .btn-lg {
//   padding: 0.75rem 1.25rem;
//   font-weight: 700;
// }

/*
 * Footer
 */
.mastfoot {
  color: rgba(255, 255, 255, 0.5);
}

/*
 * Control Panel
 */

.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: fit-content;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

// #loader {
//   position: relative;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   background-color: rgba(155, 155, 155, 0.7);
// }
